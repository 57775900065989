import pako from 'pako'
export default {
    timeToDate(cellValue) {
        let date = new Date(parseInt(cellValue * 1000));//时间戳为10位需*1000，如果为13位的话不需乘1000。
        let Y = date.getFullYear() + '-';
        let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
    },

    // 根据数字返回通道说明
    channelShow(row, column, cellValue) {
        switch (cellValue) {
            case "104": return "微信聚合码截图扫码";
            case "400": return "支付宝小荷包";
            case "401": return "网关";
            case "501": return "银联";
            case "502": return "支转卡";
            case "503": return "微转卡";
            case "504": return "卡转卡";
            case "567": return "支付宝天猫旗舰店";
            case "601": return "闲鱼SDK";
            case "602": return "闲鱼H5";
            case "701": return "PDD支付宝";
            case "702": return "PDD微信";
            case "703": return "PDD支付宝SDK";
            case "801": return "微信话费";
            case "802": return "微信慢充";
            case "803": return "微信扫码话费";
            case "805": return "微信京东慢充";
            case "806": return "微信油卡";
            case "807": return "微信小额原生";
            case "808": return "微信原生";
            case "811": return "微信苏宁商城";
            case "820": return "微信代充原生";
            case "902": return "支付宝天然气";
            case "903": return "支付宝IOS专属";
            case "904": return "淘宝代付";
            case "905": return "支付宝原生";
            case "906": return "支付宝慢充";
            case "907": return "支付宝话费";
            case "908": return "支付宝转账";
            case "909": return "支付宝原生";
            case "910": return "代金券H5";
            case "911": return "支付宝YY";
            case "912": return "支付宝安卓专属";
            case "913": return "支付宝话费SDK";
            case "916": return "口令红包";
            case "917": return "支付宝快充";
            case "918": return "支付宝电网";
            case "919": return "支付宝原生2";
            case "930": return "支付宝原生(H5)";
            case "970": return "微信花椒【夜间推荐】";
            case "993": return "支付宝UID";
        }
    },
    showLoading(text) {
        if (text === undefined) {
            text = "加载中"
        }
        if (document.getElementById("showloading")) {
            document.getElementById("showloading").innerText = text
            return
        }
        // this.hideLoading()
        var loading = document.createElement("div")
        loading.id = "showloading"
        loading.style.display = "flex"
        loading.style.justifyContent = "center"
        loading.style.flexDirection = "row"
        loading.style.alignItems = "center"
        loading.style.position = "fixed"
        loading.style.left = 0
        loading.style.top = 0
        loading.style.zIndex = 9999
        loading.style.width = "100%"
        loading.style.height = "100%"
        loading.style.color = "#ffffff"
        loading.style.backgroundColor = "rgba(0,0,0,0.6)"
        loading.innerText = text
        document.body.appendChild(loading)
    },
    hideLoading() {
        if (document.getElementById("showloading")) {
            document.body.removeChild(document.getElementById("showloading"))
        }
    },
    gzip(string){
        return btoa(pako.gzip(string))
    },
    ungzip(string){
        var binData = new Uint8Array(atob(string).split(","))
        return pako.inflate(binData,{to:"string"})
    },
    showBigImages(list,index){
        if (list[index] === undefined){
            return;
        }
        var img_url = list[index].url
        var img_html = '<icon' +
            '                class="el-icon-close" id="show_big_image_close"' +
            '                style="color: #ffffff;width: 60px;height: 60px;position: absolute; right: 0; top: 0px;font-size: 60px;"></icon>' +
            '            <icon id="show_big_image_prev" class="el-icon-arrow-left"' +
            '                  style="color: #ffffff;width: 100px;height: 100px; font-size: 60px; line-height: 100px;text-align: center"></icon>' +
            '            <img src="'+img_url+'" style="max-width: 90% ; max-height: 90%" alt="">' +
            '                <icon id="show_big_image_next" class="el-icon-arrow-right"' +
            '                      style="color: #ffffff; width: 100px;height: 100px;font-size: 60px;line-height: 100px;text-align: center"></icon>'
        if(document.getElementById("show_big_image")) {
            document.getElementById("show_big_image").innerHTML = img_html
        }else{
            var loading = document.createElement("div")
            loading.id = "show_big_image"
            loading.style.display = "flex"
            loading.style.justifyContent = "center"
            loading.style.flexDirection = "row"
            loading.style.alignItems = "center"
            loading.style.position = "fixed"
            loading.style.left = 0
            loading.style.top = 0
            loading.style.zIndex = 9999
            loading.style.width = "100%"
            loading.style.height = "100%"
            loading.style.color = "#ffffff"
            loading.style.backgroundColor = "rgba(0,0,0,0.6)"
            loading.innerHTML = img_html
            document.body.appendChild(loading)
        }
        var self = this
        // 事件侦听绑定
        document.getElementById("show_big_image_close").addEventListener("click",function (){
            if(document.getElementById('show_big_image'))document.getElementById('show_big_image').remove()
        })
        document.getElementById("show_big_image_next").addEventListener("click",function (){
            self.showBigImages(list,index+1)
        })
        document.getElementById("show_big_image_prev").addEventListener("click",function (){
            self.showBigImages(list,index-1)
        })
    },
    htmlEncode(str){
        var s = "";
        if(str.length == 0) return "";
        s = str.replace(/&/g,"&amp;");
        s = s.replace(/</g,"&lt;");
        s = s.replace(/>/g,"&gt;");
        s = s.replace(/ /g,"&nbsp;");
        s = s.replace(/'/g,"&#39;");
        s = s.replace(/"/g,"&quot;");
        return s;
    },
    htmlDecode(str){
        var s = "";
        if(str.length == 0) return "";
        s = str.replace(/&amp;/g,"&");
        s = s.replace(/&lt;/g,"<");
        s = s.replace(/&gt;/g,">");
        s = s.replace(/&nbsp;/g," ");
        s = s.replace(/&#39;/g,"'");
        s = s.replace(/&quot;/g,"\"");
        return s;
    },
    parseSize(size) {
        size = size / 1024
        if (size < 1024){
            return size.toFixed("2") + "K"
        }
        size = size / 1024
        return size.toFixed("2") + "M"
    },
}
