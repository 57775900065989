import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  //登录
  {
    path: "/login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      isLogin: false
    }
  },
  {
    path: "/home",
    name: "主页",
    component: () => import("@/views/index/index.vue"),
    children: [
      //用户管理
      {
        path: "/users/list",
        name: "用户管理",
        component: () => import("@/views/users/list.vue")
      },
      {
        path: "/users/agent",
        name: "裂变概况",
        component: () => import("@/views/users/agent.vue")
      },
      {
        path: "/users/channel",
        name: "渠道管理",
        component: () => import("@/views/users/channel.vue")
      },
      {
        path: "/users/channelSurvey",
        name: "渠道概况",
        component: () => import("@/views/users/channelSurvey.vue")
      },
      {
        path:"/users/exchangeLog",
        name: "兑换码管理",
        component: () => import("@/views/users/exchangeLog.vue")
      },
      //管理员管理
      {
        path: "/admin/list",
        name: "管理员列表",
        component: () => import("@/views/admin/list.vue")
      },
      {
        path: "/admin/admin_center",
        name: "个人中心",
        component: () => import("@/views/admin/admin_center.vue")
      },
      {
        path: "/admin/adminLog",
        name: "管理员日志",
        component: () => import("@/views/admin/adminLog.vue")
      },
      {
        path: "/admin/role",
        name: "角色管理",
        component: () => import("@/views/admin/role.vue")
      },
      //视频管理
      {
        path: "/video/list",
        name: "视频列表",
        component: () => import("@/views/video/list.vue")
      },
      {
        path: "/short_video/list",
        name: "短视频列表",
        component: () => import("@/views/short_video/short_video.vue")
      },
      {
        path: "/short_video/report",
        name: "举报列表",
        component: () => import("@/views/short_video/report.vue")
      },
      {
        path: "/short_video/uploader",
        name: "视频上传",
        component: () => import("@/views/short_video/uploader.vue")
      },
      {
        path: "/video/classify",
        name: "分类管理",
        component: () => import("@/views/video/classify.vue")
      },
      {
        path: "/video/subject",
        name: "专题管理",
        component: () => import("@/views/video/subject.vue")
      },
      {
        path: "/video/edition/type",
        name: "专题分类",
        component: () => import("@/views/video/editionType.vue")
      },
      {
        path: "/video/recommend",
        name: "推荐精选",
        component: () => import("@/views/video/recommend.vue")
      },
      //广告管理
      {
        path: "/ad/list",
        name: "广告列表",
        component: () => import("@/views/ad/list.vue")
      },
      //财务管理
      {
        path: "/finance/third",
        name: "商户管理",
        component: () => import("@/views/finance/third.vue")
      },
      {
        path: "/finance/passageway",
        name: "通道管理",
        component: () => import("@/views/finance/passageway.vue")
      },
      {
        path: "/finance/recharge",
        name: "充值记录",
        component: () => import("@/views/finance/recharge.vue")
      },
      {
        path: "/finance/flows",
        name: "金流记录",
        component: () => import("@/views/finance/flows.vue")
      },
      {
        path: "/finance/withdraw",
        name: "提现管理",
        component: () => import("@/views/finance/withdraw.vue")
      },
      //系统设置
      {
        path: "/system/parameter",
        name: "参数设置",
        component: () => import("@/views/system/parameter.vue")
      },
      {
        path: "/system/cardType",
        name: "银行卡类型设置",
        component: () => import("@/views/system/cardType.vue")
      },
      {
        path: "/system/short_video_tag",
        name: "短视频标签",
        component: () => import("@/views/system/tagList.vue")
      },
      {
        path: "/system/recharge",
        name: "充值金额设置",
        component: () => import("@/views/system/recharge.vue")
      },
      //VIP管理
      {
        path: "/vip/set",
        name: "VIP设置",
        component: () => import("@/views/vip/set.vue")
      },
      // 游戏管理
      {
        path: "/game/list",
        name: "游戏管理列表",
        component: () => import("@/views/game/list.vue")
      },
      {
        path: "/game/pt",
        name: "游戏平台管理",
        component: () => import("@/views/game/pt.vue")
      },
      {
        path: "/game/type",
        name: "游戏分类管理",
        component: () => import("@/views/game/type.vue")
      },
      {
        path: "/game/gamelog",
        name: "游戏记录",
        component: () => import("@/views/game/tz.vue")
      },
      {
        path: "/community/community_cartoon",
        name: "漫画管理",
        component: () => import("@/views/community/community_cartoon.vue")
      },
      {
        path: "/community/community_audio",
        name: "听书管理",
        component: () => import("@/views/community/community_audio.vue")
      },
      {
        path: "/community/community_tag",
        name: "标签管理",
        component: () => import("@/views/community/community_tag.vue")
      },
      {
        path: "/community/community_type",
        name: "社区分类",
        component: () => import("@/views/community/community_type.vue")
      },
      {
        path: "/community/community_story",
        name: "小说管理",
        component: () => import("@/views/community/community_story.vue")
      },
      {
        path: "/community/community_picture",
        name: "美图管理",
        component: () => import("@/views/community/community_picture.vue")
      },
      {
        path: "/community/community_tips",
        name: "打赏管理",
        component: () => import("@/views/community/community_tips.vue")
      },
      {
        path: "/community/community_report",
        name: "举报管理",
        component: () => import("@/views/community/community_report.vue")
      },
      {
        path: "/ad/community",
        name: "社区广告",
        component: () => import("@/views/community/ad_community.vue")
      },

      {
        path: "/system/notify",
        name: "弹出公告",
        component: () => import("@/views/system/notify.vue")
      },
      {
        path: "/community/community_post",
        name: "帖子管理",
        component: () => import("@/views/community/community_post.vue")
      },
      {
        path: "/system/sign",
        name: "签到活动",
        component: () => import("@/views/system/sign.vue")
      },
      {
        path: "/community/community_silent",
        name: "禁言管理",
        component: () => import("@/views/community/community_silent.vue")
      },
      {
        path: "/community/community_keyword",
        name: "关键字管理",
        component: () => import("@/views/community/community_keyword.vue")
      },
      {
        path: "/system/search",
        name: "搜索引擎",
        component: () => import("@/views/system/search.vue")
      },
      {
        path: "/event/list",
        name: "活动列表",
        component: () => import("@/views/event/list.vue")
      },
      {
        path: "/event/exchangeLog",
        name: "活动兑换记录",
        component: () => import("@/views/event/exchange_log.vue")
      },
      {
        path: "/system/oss_account",
        name: "OSS账号管理",
        component: () => import("@/views/system/oss_account.vue")
      },
      {
        path: "/system/lines",
        name: "线路管理",
        component: () => import("@/views/system/lines.vue")
      },
      {
        path: "/ys/user",
        name: "银商列表",
        component: () => import("@/views/ys/list.vue")
      },
      {
        path: "/ys/kfList",
        name: "客服列表",
        component: () => import("@/views/ys/kefu.vue")
      },
      {
        path: "/ys/messageLog",
        name: "聊天记录",
        component: () => import("@/views/ys/messageLog.vue")
      },
      {
        path: "/ys/tradLog",
        name: "上分追分记录",
        component: () => import("@/views/ys/tradLog.vue")
      },
      {
        path: "/ys/payLog",
        name: "银商充值记录",
        component: () => import("@/views/ys/payLog.vue")
      },
      {
        path: "/download/app_list",
        name: "软件列表",
        component: () => import("@/views/download/app_list.vue")
      },
      {
        path: "/download/app_type",
        name: "软件分类列表",
        component: () => import("@/views/download/type_list.vue")
      },
      {
        path: "/download/banner_list",
        name: "软件轮播图",
        component: () => import("@/views/download/banner_list.vue")
      },
      {
        path: "/download/static_app",
        name: "APP统计",
        component: () => import("@/views/download/static_app.vue")
      },
      {
        path: "/download/static_banner",
        name: "轮播统计",
        component: () => import("@/views/download/static_banner.vue")
      },
      {
        path: "/video/tagList",
        name: "标签列表",
        component: () => import("@/views/video/tagList.vue")
      },
      {
        path: "/video/periodsList",
        name: "每周必看",
        component: () => import("@/views/video/periodsList.vue")
      },
      {
        path: "/system/commentList",
        name: "评论列表",
        component: () => import("@/views/system/commentList.vue")
      },
      {
        path: "/agent/config",
        name: "代理配置",
        component: () => import("@/views/agent/config.vue")
      },{
        path: "/agent/agentLog",
        name: "代理收益派发日志",
        component: () => import("@/views/agent/agentLog.vue")
      },{
        path: "/ai/image",
        name: "图片换脸模板",
        component: () => import("@/views/ai/ai_image.vue")
      },{
        path: "/ai/type",
        name: "AI分类管理",
        component: () => import("@/views/ai/ai_type.vue")
      },{
        path: "/ai/video",
        name: "视频换脸模板",
        component: () => import("@/views/ai/ai_video.vue")
      },{
        path: "/ai/ty_log",
        name: "脱衣记录",
        component: () => import("@/views/ai/ai_ty_log.vue")
      },{
        path: "/ai/image_log",
        name: "图片换脸记录",
        component: () => import("@/views/ai/ai_image_log.vue")
      },{
        path: "/ai/video_log",
        name: "视频换脸记录",
        component: () => import("@/views/ai/ai_video_log.vue")
      },{
        path: "/ai/setting",
        name: "AI设置",
        component: () => import("@/views/ai/ai_setting.vue")
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 重新定义路由， 判断不存在的路由
const originPush = router.push
router.push = function push(location){
  var exist = false
  for(const route of routes){
    if (route.path == location){
      exist = true
      break
    }
    if (route.children !== undefined){
      for(const sub_route of route.children){
        if (sub_route.path == location){
          exist = true
          break;
        }
      }
      if (exist){
        break;
      }
    }

  }
  if (!exist){
    // 不存在的  清理掉
    localStorage.removeItem("current_router")
  }
  return originPush.call(this,location).catch(err=>err)
}

export default router
